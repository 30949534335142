body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wiki-content {
  font-size: 1.2rem;
}

.wiki-content img {
  max-width: 100%;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  border-radius: 3px;
}
